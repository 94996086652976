/* eslint-disable */

import React from "react"
import { Element } from "react-scroll"
import { Modal, Button } from "antd"
import Img from "gatsby-image"
import { graphql, navigate } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ExternalLink from "../../components/external-link"

const candidates = [
  {
    name: "Alex Pellitteri",
    statement: (
      <>
        <p>
          My first experience in politics was in 2015 when I interned for my
          centrist local state assemblymember. At the time my concept of
          politics was “Democrats are good and Republicans are bad,” and I had
          dreams of becoming a successful political operative. Then Bernie
          Sanders ran for president. I began volunteering for his 2016 campaign
          and helped mobilize other high school students. This experience
          introduced me to the idea of democratic socialism and forced me to
          reevaluate my previous assumptions and aspirations. I realized that
          politics was not a competition between two parties, but rather a war
          between the powerful, domineering ruling class on one side and the
          working class who create all their wealth on the other. I realized
          that change does not come from any one individual, but rather from an
          organized and militant working class. After Bernie’s campaign ended, I
          no longer saw the world the same and knew I had to keep organizing.
        </p>
        <p>
          I joined DSA in April 2017 when I was a junior in high school and have
          been highly active in YDSA and DSA ever since. My political home has
          been NYC-DSA where I served on the inaugural South Brooklyn Branch
          Organizing Committee, chapter Steering Committee, and the Citywide
          Leadership Committee, and where I was a priority campaign
          representative on our Socialists in Office committee. I also served as
          treasurer of NYC-YDSA and where helped raise over $10,000 in
          scholarships so YDSA organizers can attend national events.
        </p>
        <p>
          In 2020, I was the campaign manager for DSA-endorsed Assemblymember
          Marcela Mitaynes. Her campaign began during the COVID-19 pandemic, so
          suddenly we had to throw out the old organizing playbook and needed to
          develop new and creative ways how to do basic tasks such as
          fundraising or recruiting volunteers. We also had to develop a
          socialist electoral response to the COVID-19 pandemic and the George
          Floyd uprising. It became our job to communicate that the root cause
          of these tragedies was capitalism and their only true solution was
          socialism. After managing Marcela’s campaign, I served as a member of
          the NYC-DSA Socialist in Office Committee, the coordinating body of
          our elected officials and their strategy. This experience solidified
          my belief that DSA must achieve political independence from the
          Democrats to win socialism. I saw how we can often default to the
          “liberal” legislative approach of passing legislation through personal
          relationships rather than mass action. This approach often requires
          downplaying our socialist politics and a hesitancy to challenge
          powerful Democratic politicians. It is not enough to just win an
          election or pass a law. For our organizing to be effective, we need to
          actively present socialism as an alternative to the Democrats and
          Republicans.
        </p>
        <p>
          In YDSA, I helped start the Hunter College chapter which is part of
          CUNY, NYC’s public university system. CUNY is a strategic place for
          building a more racially and economically diverse DSA. Our chapter
          grew exponentially after we adopted the New Deal 4 CUNY campaign to
          make public college free. As a steering committee member of the
          campaign, I helped lead our efforts to talk to thousands of
          working-class CUNY students and demand a much-needed increase for CUNY
          in the New York state budget. The campaign helped grow NYC-YDSA to
          include five new YDSA chapters at CUNY and helped shape YDSA’s
          political strategy for fighting for free college. My experience
          starting a YDSA chapter demonstrated how crucial transparency and
          democracy are. Part of the reason why the New Deal 4 CUNY campaign was
          so successful was because each member felt ownership of the campaign.
          They were not just participants, they helped shape our political
          direction. Through YDSA I’ve helped start chapters from the ground up
          in difficult organizing conditions and have seen how having a campaign
          can exponentially grow a developing chapter. It underscored the
          importance of ensuring every DSA chapter has the resources to run
          sophisticated campaigns that develop leaders.
        </p>
        <p>
          I deeply believe that DSA can play a major role in ending capitalism
          and liberating the working class. However, this will only be possible
          if we can build a militant, democratic, and transparent organization.
        </p>
      </>
    ),
  },
  {
    name: "Kristin Schall",
    statement: (
      <>
        <p>
          I did not grow up in a political family. In fact, politics were hardly
          ever spoken about and I am not even sure if my family even votes. But
          I became interested in left politics at a relatively young age. I was
          intrigued by the anti-globalization movement and protests in Seattle
          and inspired by Ralph Nader’s run in 2000. Like many people who came
          of age in the early 2000’s, 9/11 was a defining political moment in my
          life. I was a senior in high school on 9/11 and my interest in left
          politics that had been building in the abstract through punk music and
          watching protests on TV was solidified in the year between the attacks
          and the beginning of the Iraq War. While the media and politicians
          pushed for war, I was deeply disturbed by the Islamophobic, jingoistic
          narrative that was being pushed all around me. As a freshman in
          college in NYC, I quickly became active in antiwar work on campus and
          in February 2003 I attended my first demonstration against the war
          along with hundreds of thousands of others. While I have been to and
          helped organize many demonstrations since, I will never forget the
          feeling of power and hope present in that mass of people all standing
          up together for what we believed.
        </p>
        <p>
          Between 2003 and when I joined DSA in 2016, I was steadily active on
          the left as an organized socialist in the Socialist Party USA where I
          served in various leadership roles, including on the national
          committee and as editor of the magazine. During that time, I worked on
          campaigns for single-payer healthcare and against austerity budgets
          and charter schools. I was also active on the direct action committee
          during Occupy Wall Street. Through all of this work, I continued to
          read, learn, and hone my political analysis. I learned through doing —
          everything from writing a press release to doing list work to
          canvassing to planning an action from start to finish.
        </p>
        <p>
          I brought all of this background experience with me when I joined DSA.
          In DSA, I have served twice as a member of the organizing committee of
          NYC-DSA’s South Brooklyn branch, worked on member outreach and
          development, served as a field lead for two successful electoral
          campaigns as well as for DSA for Bernie, organized a panel on
          single-payer healthcare, and attended countless rallies in support of
          DSA campaigns and causes.
        </p>
        <p>
          To echo Lenin, politics starts in the millions and while we are not
          there yet, DSA is the first organization I have been involved in that
          has real potential to build a mass, democratic, multi-tendency,
          explicitly socialist movement. I am running for NPC because I believe
          in that potential. We have worked hard in DSA over the last seven
          years and have seen the beginning of the fruits of our labor. As we
          begin to organize in this post-Bernie landscape, we have a lot of
          lessons to learn from. I want to push DSA to take steps towards
          becoming a party-like organization, to continue to expand our roots
          within the union rank and file in order to link the socialist movement
          to the labor movement, and to greatly expand our outreach through
          expanded publications and media. As a member of the NPC, I plan to
          work on supporting the Democracy Commission if it is passed at
          convention, membership development, and political education. For DSA
          to truly capitalize on our numbers, we need a massive expansion of
          democracy and to guide members to develop a strong ideological
          understanding of our socialist project in order to fortify us to work
          for the long term.
        </p>
      </>
    ),
  },
  {
    name: "Laura Wadlin",
    statement: (
      <>
        <p>
          Bernie's first presidential run completely changed my life. I was a
          self-identified socialist but a loyal Democratic voter. I had never
          participated in a true socialist or leftist organization, and I was
          turned off by the lifestyle activism scene in Portland. But 2015
          taught me two invaluable lessons: there are millions of other people
          around the country who want exactly what I want, and what we want is
          very different from what many so-called “progressive” politicians
          want.
        </p>
        <p>
          Immediately after Bernie's loss in 2016, I started organizing at the
          school I worked at because he had said “every worker should have a
          union.” I contacted a union and started getting mentored by one of
          their external organizers. Sadly, that effort stalled and I moved on.
          But I took everything I learned and applied it to an organizing
          campaign at my next job at a community center. That campaign got close
          to victory — until I got fired, along with the other lead organizer. I
          saw how the employer used racism to cut into solidarity among workers,
          how self-appointed “community leaders” used their positions at
          nonprofits to gatekeep power and line their own pockets, and how that
          conflict was a transformational experience for all of my coworkers.
        </p>
        <p>
          I had been a member of DSA since I heard about it in the news in 2017,
          but I got involved when I was looking for people to help my coworkers
          who were still fighting the good fight at my old job. I got connected
          to the Portland DSA Labor Working Group in 2018, and I've been a core
          organizer in the chapter ever since. In 2019 I helped coordinate the
          Portland DSA for Bernie campaign which recruited dozens of new chapter
          members, several of whom became elected leaders in the chapter. I also
          served on the national DSA for Bernie comms team, and in 2020 I
          organized two “Bernie Journey” trips for Portland DSA members: one to
          Iowa where we knocked doors in a Spanish-speaking mobile home park,
          and the other to Las Vegas where we did guerilla canvassing of
          housekeepers on the Las Vegas Strip.
        </p>
        <p>
          In 2021 I was elected co-chair of Portland DSA and helped lead the
          chapter's strike solidarity work for workplace campaigns in a variety
          of industries. Since then we have grown a network of dozens of
          rank-and-file unionists who have joined DSA, and some of whom have
          become chapter leaders and are even considering running for office as
          open socialists. This work inspired the Oregon Federation of Nurses
          and Health Professionals to make a $10,000 donation to the chapter for
          three years in a row, saying that the money would have otherwise gone
          to the Democratic Party.
        </p>
        <p>
          Since 2022 I have been a lead steward in my own union, the Portland
          Community College Federation of Faculty and Academic Professionals.
          I'm an instructor of English for Speakers of Other Languages, and I've
          helped make our department the most unified and militant group at PCC
          (we even got our boss fired a few months ago!). For the past year I
          have co-chaired our Contract Action Team and am actively preparing us
          for a strike this year. I'm very proud of the progress we've made, but
          I know my contributions were only possible because of the mentorship
          and support I got from my fellow DSA members.
        </p>
        <p>
          As a member of the NPC, I will replicate that leadership development
          at a national level and continue to bridge the gap between the
          socialist and labor movements.
        </p>
      </>
    ),
  },
]

class NPCSlate2023 extends React.Component {
  state = { visible: false, candidate: null }

  showModal = (candidate) => {
    this.setState({
      visible: true,
      candidate: candidate,
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  images = () => this.props.data.allFile.edges.map((edge) => edge.node)

  image = (candidate) => {
    const name = candidate.name.split(" ")[0].toLowerCase()
    return this.images().find((image) => image.name === name).childImageSharp
      .fluid
  }

  render() {
    console.log(this.props)
    return (
      <Layout
        title="Convention 2023"
        color="blue"
        heroImageName="convention-2023"
        layoutClassName="convention-2023"
      >
        <SEO title="Convention 2023" ogImage="convention" />

        <div className="content-column content-column-candidates">
          <Element name="candidates" id="candidates">
            <h2>Meet the Candidates</h2>
          </Element>

          <div className="candidate-cards">
            {candidates.map((candidate) => (
              <div
                className="candidate-card"
                onClick={() => this.showModal(candidate)}
                key={candidate.name}
              >
                <CandidatePhoto
                  img={this.image(candidate)}
                  alt={candidate.name}
                />

                <div className="candidate-card__content">
                  <h3>{candidate.name}</h3>
                  <br />
                  <Button>Read more&hellip;</Button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="content-column">
          <Element name="priorities" id="priorities">
            <p>
              The 2023 DSA National Convention will be held in Chicago from
              Friday, August 4 to Sunday, August 6. For more information, please
              check the official{" "}
              <ExternalLink href="https://convention2023.dsausa.org/">
                DSA convention website
              </ExternalLink>
              . Bread & Roses is proud to be proposing a host of ideas to the
              convention and running a number of candidates for DSA's National
              Political Committee.
            </p>
            <h2>What could DSA be by 2030?</h2>
            <p>
              A <strong>party-like electoral organization</strong> with a strong
              national profile and identity, disciplined elected officials who
              see themselves as tribunes for working-class people, and hundreds
              of thousands or even millions of people who support and vote year
              in and year out for the DSA candidates in their districts. A
              party-like organization that champions fights against racism,
              sexism, the anti-trans rights movement, and other far right forces
              and organizes fights for big and bold reforms.
            </p>
            <p>
              A <strong>union organizing center</strong> for tens or hundreds of
              thousands of rank-and-file labor organizers and a massive{" "}
              <strong>solidarity network</strong> capable of mobilizing
              working-class communities around the country. Working side-by-side
              with many more workplace activists, DSA labor organizers could
              help rebuild and grow U.S. unions into a fighting, democratic, and
              left-wing labor movement.
            </p>
            <p>
              A <strong>key part of a growing international left</strong> with
              relations with democratic socialist parties all around the world,
              working together to build a democratic socialism for the 21st
              century that is thoroughly anti-imperialist, ecological, and
              committed to fighting against oppression and authoritarianism.
            </p>
            <p>
              A{" "}
              <strong>
                powerful, effective, and democratic membership group
              </strong>{" "}
              that trains a new generation of democratic socialist organizers in
              how to run campaigns; that recruits and develops more socialists
              with a special focus on supporting working-class, female, queer,
              and Black and brown organizers; and that lives democracy in our
              politics every day.
            </p>
            <p>
              We in Bread & Roses want to work with the big tent of DSA to make
              this vision of a new, better, and bolder DSA a reality. We’re
              going into the 2023 DSA National Convention with this vision in
              mind. And while our focus in 2023 is on labor, electoral politics,
              internationalism, and internal organizing and reform, we’re
              excited to support work being done by other groups and tendencies
              in DSA to build important campaigns for civil rights, housing
              justice, a Green New Deal, and more.
            </p>
            <h2>Our Movement and the Moment</h2>
            <h3>
              Beating Back the Far Right and Building an Electoral Alternative
            </h3>
            <p>
              In 2024, centrist Democrats and far right Republicans will fight
              to take power in D.C. and state capitals all across the country.
              Donald Trump and Joe Biden are neck and neck in most polls. The
              threat of an even more dangerous and more authoritarian Republican
              presidential administration than any that have come before feels
              more real this summer than it has in recent memory.
            </p>
            <p>
              We know that Democrats have no real strategy for defeating the far
              right in 2024 or beyond. Some argue that we need to join a
              “popular front” with Joe Biden and the Democratic Party in order
              to beat the Republicans. This is a trap and a mistake. It will
              take an independent, democratic socialist alternative to galvanize
              the left-wing of the Democrats’ working-class base and to unite
              them with the millions of working-class nonvotes to permanently
              deny the far right a majority — and ultimately move us towards a
              more humane and just world. DSA can be a key part of building this
              alternative, if we can create a stronger, more party-like national
              organization.
            </p>
            <p>
              DSA’s National Electoral Commission has done good work in
              supporting DSA’s electoral strategy, but we think that it can be
              bolder and create a more clearly-defined national strategy for
              DSA’s chapters. That’s why we have put forward Consensus
              Resolution Amendment I:{" "}
              <ExternalLink href="https://docs.google.com/document/d/13AN2qSJpIfifCixu6DS7nrTJSbQxmoGh-eYBtUqXc0I/edit#heading=h.pxvpbxxib5rn">
                Act Like an Independent Party
              </ExternalLink>
              , an amendment to the NEC resolution. Act Like an Independent
              Party would have DSA take meaningful steps to do just that, from
              building our own voter and volunteer lists to developing a
              national identity for DSA electoral campaigns. We also submitted
              Member-Submitted Resolution 2:{" "}
              <ExternalLink href="https://docs.google.com/document/d/1pcYUezxHMGMtjdYCBeJveRYVJSp0lMGY7w29tfuuQFk/edit">
                Defend Democracy through Political Independence
              </ExternalLink>
              . Defend Democracy lays out a strategy for fighting the right and
              beating back attacks on trans rights, Black people, abortion
              rights, labor rights, and more in 2024 without sacrificing our
              political independence from Joe Biden and the corporate Democratic
              Party.
            </p>
            <p>
              We’re also proud to be running Alex Pellitteri from NYC-DSA for
              the National Political Committee, who has significant experience
              as an electoral organizer for several of NYC-DSA’s winning
              campaigns. If elected to the NPC, Alex will work to turn the ideas
              in Act Like an Independent Party into a reality on the NPC.
            </p>
            <h3>A Labor Upsurge?</h3>
            <p>
              As real as the threat from the far right is, we also can’t lose
              sight of the huge and growing opportunity before us: the rising
              interest in union organizing among bigger and bigger groups in
              society plus the stunning achievements of the union reform
              movement in recent years. Young people especially are interested
              in joining unions and building labor’s power. Big national reform
              caucuses like Unite All Workers for Democracy and Teamsters for a
              Democratic Union are helping transform major U.S. unions into
              fighting operations that can win better contracts for their
              members and organize unorganized workers — and this work is
              happening at the local level, too.
            </p>
            <p>
              The rank-and-file strategy, which both DSA and Bread & Roses
              proudly champion, is bearing fruit. Now more than ever, we need to
              double down on building rank-and-file power and transforming
              unions at the national and local levels, and doing more than ever
              to support projects like Labor Notes that are helping to build the
              class-struggle union movement. Doing so is the path to building
              worker power in the economy, and it’s also a key to changing
              political consciousness and building a left-wing electoral
              alternative to the far right.
            </p>
            <p>
              DSA’s National Labor Commission has drafted an excellent consensus
              resolution along these lines, and we are proud to support it. The
              NLC consensus resolution would have DSA continue the work of
              sending our members to get union jobs and work to transform their
              unions. It would commit us to supporting important labor projects
              like the Emergency Workplace Organizing Committee and Labor Notes.
            </p>
            <p>
              We’re proud to be running Laura Wadlin from Portland, Oregon for
              the National Political Committee, who would help ensure that the
              NLC resolution gets fully implemented. Laura is an ESL instructor
              and a steward with her union at Portland Community College. Last
              year she was cochair of Portland DSA and has helped lead much of
              the chapter's impressive strike solidarity work.
            </p>
            <h3>
              Working Across Borders for Democratic Socialism and Against
              Imperialism and Climate Catastrophe
            </h3>
            <p>
              On the world stage, the moment calls for a strong democratic
              socialist movement that is strengthening relationships across
              borders and learning from all the different national experiments
              in rebuilding the socialist movement. Only an
              internationally-oriented, democratic socialist movement can really
              take on climate catastrophe and fight back against the return of
              inter-imperial rivalries. Only an internationally-oriented
              democratic socialist movement in the U.S. can really challenge
              U.S. imperialism and create space for the left and social
              movements around the world to break with global capitalism.
            </p>
            <p>
              DSA needs to be part of rebuilding this internationalist,
              anti-imperialist, and democratic global left. We can take big
              strides in this direction by adopting a big tent approach to
              building international relations, working with parties in and out
              of government, from left-wing social democratic and populist
              parties to multi-tendency left parties and parties and
              organizations that have embraced a more democratic orientation
              coming out of the various communist, Maoist, and Trotskyist
              traditions. We also need to be consistent allies of movements
              around the world fighting for democracy and self determination.
            </p>
            <p>
              DSA’s International Commission has done admirable work in starting
              to build these bonds. We have some disagreements with some
              comrades on the IC though in terms of who we build relationships
              with and how we as DSA respond to international events. We want
              DSA to be a more reliable supporter of movements for democracy
              abroad and to not withhold support for democratic movements in
              countries where the governing regime is not a U.S. ally. The fight
              for democracy and a better world is happening in struggles against
              the ruling governments in Israel and Hungary, but it’s also
              happening in fights against the ruling governments in Venezuela,
              Russia, and Nicaragua. DSA has been a consistent ally of popular
              movements in Israel and Hungary, but has been reluctant to support
              popular movements in Venezuela, Russia, Nicaragua, and beyond.
              That needs to change. That’s why we submitted Consensus Resolution
              Amendment C:{" "}
              <ExternalLink href="https://docs.google.com/document/d/1ftJ3mTyIYMFZoxHwVPcIJKzWsrQcRsb4kMqonY8HGbs/edit">
                For a Class-Struggle Internationalism
              </ExternalLink>
              . Our vision of international solidarity is one that unites all
              working people fighting against ruling classes and authoritarian
              governments.
            </p>
            <h3>
              Building a Fighting, Effective, and Democratic Membership
              Organization
            </h3>
            <p>
              A national big tent, multi-tendency, and democratic membership
              organization was a dream of many on the U.S. left for decades
              before 2016. DSA has become that organization. It’s our political
              home and we in Bread & Roses are proud to be part of it.
            </p>
            <p>
              We’re concerned about DSA’s health and its effectiveness at the
              national level, and we want to use the 2023 DSA National
              Convention and the 2023-2025 National Political Committee term to
              start to address those concerns. A dip in membership numbers, the
              absence of prominent DSA spokespeople, and a growing sense that
              the senior directorial staff in DSA’s national office try to shape
              the priorities of the organization’s democratically elected
              leaders rather than taking direction from them are holding DSA
              back.
            </p>
            <p>
              That’s why we’ve proposed a host of changes to DSA.
              Member-Submitted Resolution 3:{" "}
              <ExternalLink href="https://docs.google.com/document/d/1pLCqVKiyRifZruCxPjWWS7vdNW5HIgxx5sBKXYK9EKk/edit">
                Resolution on Full-time National Chairs
              </ExternalLink>{" "}
              would allow DSA to elect two full-time spokespeople to represent
              DSA nationally. Member-Submitted Resolution 8:{" "}
              <ExternalLink href="https://docs.google.com/document/d/e/2PACX-1vTM6nwIdFn2lZDaWgkFvQbYsU7lzFs3SOv6MQERCijYynKDYcwHywMdr4qye57461ohCC51MqH_FR7w/pub">
                For a Political, Prolific and Democratic DSA Editorial Board
              </ExternalLink>{" "}
              — which we proposed with other DSA tendencies — would revamp DSA's
              official publications and put them under the leadership of a
              democratically-chosen editorial board. Member-Submitted Resolution
              16:{" "}
              <ExternalLink href="https://docs.google.com/document/d/1NnuUZD3Emn7oCw1PGrCjetf-Yo0ctTjY_oYTW_z_8II/edit">
                Renew the National Activist Conference
              </ExternalLink>{" "}
              would bring back a national conference for DSA activists in the
              off-years between national conventions, creating more space for
              our members to meet and learn from each other. Finally,
              Member-Submitted Resolution 10:{" "}
              <ExternalLink href="https://docs.google.com/document/d/1T2eOb7Hn65T8YyAL7LMp24rRGaGAK6M6hc9yyStgvzM/edit">
                Launch a Democracy Commission for DSA
              </ExternalLink>{" "}
              looks to the future. It would create a multi-tendency committee to
              make recommendations for how DSA can be more fully transformed at
              the 2025 National Convention.
            </p>
            <p>
              We’re also proud to be running Kristin Schall, an at-large member
              of DSA at the moment (future member of Mid-Hudson Valley DSA), for
              National Political Committee. Kristin is a preschool teacher and a
              mother. She has organized on the left for over 20 years and has
              extensive experience and a deep interest in building a more
              democratic, member-driven organization. Kristin will work
              tirelessly on the NPC to make DSA a more effective and democratic
              organization.
            </p>
            <h2>Our Proposals</h2>
            <p>
              Bread & Roses has written or cowritten with other tendencies seven
              proposals to DSA’s national convention. We’re also a strong
              supporter of, and our members helped draft, the DSA’s National
              Labor Commission’s consensus resolution to recommit to, and deepen
              our work inspired by, the rank-and-file strategy. As we described
              above, these proposals are designed to move DSA towards becoming a
              party-like electoral organization, a union organizing center for
              socialist rank-and-file labor activists, a key part of the growing
              international left, and a powerful, effective, and democratic
              membership group.
            </p>
            <p>
              In the weeks to come before the 2023 DSA National Convention,
              Bread & Roses will also be meeting to discuss our orientation to
              the dozens of other proposals for campaigns, organizational
              reforms, and tactical and strategic orientations put forward by
              others in DSA. While labor, electoral, international, and internal
              DSA organizing are the main focus of B&R’s attention at the 2023
              DSA National Convention, we are also interested in learning about
              and supporting the work of others in DSA in fighting for civil
              rights, housing justice, a Green New Deal, and so much more.
            </p>
            <h2>Our Candidates</h2>
            <p>
              We’re proud to support Alex Pelitterri (New York City DSA),
              Kristin Schall (At large), and Laura Wadlin (Portland DSA) as
              candidates for the National Political Committee of DSA. Alongside
              B&R’s candidates for{" "}
              <ExternalLink href="https://ydsa.breadandrosesdsa.org/">
                YDSA’s National Coordinating Committee
              </ExternalLink>
              , Alex, Kristin, and Laura will work to build a fighting,
              democratic, and effective DSA. If you agree with our vision, rank
              them at the top of your ballot at the 2023 DSA National
              Convention.
            </p>
            {/* <p>
              <Img fluid={this.props.data.slate.childImageSharp.fluid} />
            </p> */}
            {/* <p>
              <p style={{ marginTop: 30, textAlign: "center" }}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  icon="pushpin"
                  onClick={() => navigate("/convention-2023-resolutions/")}
                  style={{
                    whiteSpace: "normal",
                    height: "auto",
                    minHeight: "60px",
                    fontSize: 24,
                  }}
                >
                  8 Convention Resolutions to Support
                </Button>
              </p>
              <p style={{ marginTop: 30, textAlign: "center" }}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() => navigate("/convention-2023-vision/")}
                  icon="bulb"
                  style={{
                    whiteSpace: "normal",
                    height: "auto",
                    minHeight: "60px",
                    fontSize: 24,
                  }}
                >
                  Full Convention Vision
                </Button>
              </p>
              <p style={{ marginTop: 30, textAlign: "center" }}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() =>
                    window.open(
                      "https://breadandrosesdsa.us8.list-manage.com/subscribe?u=9448901c8beae38362c8093d1&id=c2b8e01b0d",
                      "_blank"
                    )
                  }
                  icon="form"
                  style={{
                    whiteSpace: "normal",
                    height: "auto",
                    minHeight: "60px",
                    fontSize: 24,
                  }}
                >
                  Subscribe for Updates
                </Button>
              </p>
            </p> */}
          </Element>
        </div>

        <Modal
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={600}
        >
          {this.state.candidate && (
            <>
              <h2>{this.state.candidate.name}</h2>
              <CandidatePhoto
                img={this.image(this.state.candidate)}
                alt={this.state.candidate.name}
              />
              {this.state.candidate.statement}
            </>
          )}
        </Modal>
      </Layout>
    )
  }
}

const CandidatePhoto = ({ img, alt }) => (
  <Img className="candidate-photo" fluid={img} alt={alt} />
)

export const Convention2023SlatePhoto = graphql`
  fragment convention2023SlatePhoto on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query Candidates2023Query {
    allFile(
      filter: { absolutePath: { regex: "/convention-2023/headshots/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    slate: file(relativePath: { eq: "convention-2023/slate.png" }) {
      ...convention2023SlatePhoto
    }
  }
`

export default NPCSlate2023
